import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Trans } from '@lingui/macro';

import styles from './Pagination.scss';

const Pagination = ({ page, pages, pathname, query }) => {
  const previousPage = page > 1 && page === 2 ? undefined : page - 1;

  return (
    <div className={`mt-20 mb-40 ${styles.container}`}>
      {page > 1 && (
        <Link
          to={{
            pathname,
            search: queryString.stringify({
              ...query,
              page: previousPage,
            }),
          }}
          className="button is-link is-inverted is-medium"
          rel="prev"
        >
          <span className="icon is-small">
            <i className="fa fa-angle-left" />
          </span>
          <Trans>Prev</Trans>
        </Link>
      )}
      {page < pages && (
        <Link
          to={{
            pathname,
            search: queryString.stringify({
              ...query,
              page: page + 1,
            }),
          }}
          rel="next"
          className="button is-link is-inverted is-medium"
          style={{ float: 'right' }}
        >
          <Trans>Next</Trans>
          <span className="icon is-small">
            <i className="fa fa-angle-right" />
          </span>
        </Link>
      )}
    </div>
  );
};

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  pathname: PropTypes.string.isRequired,
  query: PropTypes.shape({
    location: PropTypes.string,
    gender: PropTypes.string,
  }),
};

export default Pagination;
