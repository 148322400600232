import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Trans } from '@lingui/macro';

import * as Specialist from 'modules/specialist/constants';
import SearchResult from './SearchResult';
import FreeResult from './FreeResult';

const SearchResults = props => {
  const { query, searchResults, onMouseEnter, onMouseLeave, selectedProfileId } = props;
  const { specialists } = searchResults;
  const fullSearchResultSpecialists = specialists.filter(item => item.level >= Specialist.FULL_PROFILE);
  const freeSearchResultSpecialists = specialists.filter(item => item.level < Specialist.FULL_PROFILE);

  return (
    <div onMouseLeave={onMouseLeave}>
      {fullSearchResultSpecialists.length > 0 && (
        <React.Fragment>
          <h1 className="is-size-5 is-size-7-mobile pt-5 pb-5 pl-5">
            <Trans>
              Book an appointment online with {pluralize(query.specialization, fullSearchResultSpecialists.length)} in{' '}
              {query.town}
            </Trans>
          </h1>
          {fullSearchResultSpecialists.map(specialist => (
            <SearchResult
              key={`specialist-${specialist.id}-${specialist.practiceId}`}
              id={`specialist-${specialist.id}-${specialist.practiceId}`}
              specialist={specialist}
              selectedProfileId={selectedProfileId}
              onMouseEnter={onMouseEnter}
            />
          ))}
        </React.Fragment>
      )}

      {freeSearchResultSpecialists.length > 0 && (
        <div>
          <h5 className="title is-5 pt-40 pb-0 fontweight-400">
            <Trans>
              Other {pluralize(query.specialization, freeSearchResultSpecialists.length)} in {query.town}
            </Trans>
          </h5>
          {freeSearchResultSpecialists.map(specialist => (
            <FreeResult
              key={`specialist-${specialist.id}-${specialist.practiceId}`}
              id={`specialist-${specialist.id}-${specialist.practiceId}`}
              data={specialist}
              selectedProfileId={selectedProfileId}
              onMouseEnter={onMouseEnter}
            />
          ))}
        </div>
      )}
      {searchResults.practices &&
        searchResults.practices.map(practice => (
          <FreeResult
            key={practice.id}
            id={`practice-${practice.id}`}
            data={{
              ...practice,
              profileType: 'practice',
              firstName: practice.name,
              lastName: '',
            }}
            selectedProfileId={selectedProfileId}
            onMouseEnter={onMouseEnter}
          />
        ))}
    </div>
  );
};

SearchResults.propTypes = {
  searchResults: PropTypes.shape({
    specialists: PropTypes.arrayOf(
      PropTypes.shape({
        level: PropTypes.number,
      })
    ),
  }).isRequired,
  query: PropTypes.shape({
    specialization: PropTypes.string,
    town: PropTypes.string,
  }).isRequired,
  selectedProfileId: PropTypes.string,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

export default SearchResults;
