import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';

import { modalActions } from 'modules/modal/actions';
import SearchBar from 'modules/searchBar';
import { actions as commonActions } from 'modules/common/actions';
import styles from './NotFound.scss';

class NotFound extends Component {
  componentWillMount() {
    this.props.dispatch(commonActions.onPageSetStatus('404'));
  }

  render() {
    return (
      <div className="pt-60 pb-70">
        <div className={`container ${styles.container}`}>
          <div className="toptext pb-30">
            <h3 className="title is-3 has-text-primary-grey">
              <Trans>
                Sorry, we couldn’t find any professional for your <br />
                search…
              </Trans>
            </h3>
            <h3 className="title is-3 has-text-primary-grey">
              <Trans>Try with other search criteria.</Trans>
            </h3>
          </div>

          <div className="mb-30">
            <SearchBar {...this.props.query} />
          </div>

          <div className="textblock has-text-centered mb-30">
            <div className="notification is-warning has-text-centered mb-20">
              <div className="is-size-6">
                <Trans>Sorry, we could not find any professional for your search…</Trans>
              </div>
            </div>
          </div>

          <div className="cont is-light-bg mb-20 pt-20 pb-20">
            <div className="columns">
              <div className="column is-9">
                <div className="lt pl-25">
                  <h5 className="fontfamily-roboto title is-5">
                    <Trans>You are a healthcare professional and you are not appearing in this search?</Trans>
                  </h5>
                  <p>
                    <Trans>Get in touch to have your practice listed for free.</Trans>
                  </p>
                </div>
              </div>
              <div className="column">
                <div className="rt">
                  <button
                    className="button is-primary"
                    onClick={() => this.props.dispatch(modalActions.showListPracticeModal())}
                  >
                    <Trans>Add your practice</Trans>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NotFound.propTypes = {
  dispatch: PropTypes.func.isRequired,
  query: PropTypes.shape({
    name: PropTypes.string,
    specialization: PropTypes.string.isRequired,
    gender: PropTypes.string,
    location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
};

export default connect()(NotFound);
