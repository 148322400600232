// @flow

import React from 'react';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';

import type { SearchResultSpecialist, CalendarAvailableTimeSlots } from 'types';

import ProfileLink from 'components/link/ProfileLink';
import StarRating from 'components/starRating';
import SpecialistPicture from 'components/specialist/specialistPicture';
import EnquireButton from 'components/enquireButton';
import BookingCalendarMultiSpec from 'components/booking/BookingCalendarMultiSpec';
import trackEvent from 'utils/trackEvent';

import styles from './SearchResult.scss';

export type Props = {|
  id: string,
  specialist: SearchResultSpecialist,
  selectedProfileId?: number,
  onMouseEnter: (specialist: SearchResultSpecialist) => void,
  availableTimeSlots: CalendarAvailableTimeSlots,
  isLessThanMobileBreakpoint: boolean,
|};

class SearchResult extends React.Component<Props> {
  shouldComponentUpdate = (nextProps: Props) => {
    if (
      this.props.specialist !== nextProps.specialist ||
      this.props.availableTimeSlots !== nextProps.availableTimeSlots ||
      nextProps.selectedProfileId === this.props.id ||
      this.props.id === this.props.selectedProfileId
    ) {
      return true;
    }

    return false;
  };

  render() {
    const { specialist, availableTimeSlots, selectedProfileId, isLessThanMobileBreakpoint } = this.props;
    const hasTimeSlots = availableTimeSlots.firstSlot || availableTimeSlots.availabilities.length > 0;

    const handleBookingButtonClick = () => {
      trackEvent('Paid Traction', 'Open Booking', `specialist (${specialist.id}) specialist`);
    };
    const name = `${specialist.firstName} ${specialist.lastName}`;

    return (
      <div
        id={this.props.id}
        className="mb-20 mt-10 has-background-white"
        onMouseEnter={() => this.props.onMouseEnter(this.props.specialist)}
      >
        <div
          className={`columns is-mobile is-multiline ${
            this.props.id === selectedProfileId ? styles.containerHover : styles.container
          }`}
        >
          <div className="column is-3-mobile is-2-tablet has-text-centered">
            <ProfileLink
              profileType="specialist"
              town={specialist.town}
              borough={specialist.borough}
              slug={specialist.slug}
              speciality={specialist.specialization}
              hash={specialist.practiceSlug}
            >
              <SpecialistPicture specialistId={specialist.id} profilePicture={specialist.profilePicture} />
            </ProfileLink>

            <ProfileLink
              className="is-size-7"
              profileType="specialist"
              town={specialist.town}
              borough={specialist.borough}
              slug={specialist.slug}
              speciality={specialist.specialization}
              hash={specialist.practiceSlug}
            >
              <Trans>View Profile</Trans>
            </ProfileLink>

            {specialist.logo > 0 && (
              <ProfileLink
                profileType="practice"
                town={specialist.town}
                borough={specialist.borough}
                slug={specialist.practiceSlug}
                hash={specialist.practiceSlug}
                className={styles.practiceLogo}
              >
                <img src={`/images/profile/Id${specialist.practiceId}-ConvertPic.png`} alt="practice logo" />
              </ProfileLink>
            )}
          </div>
          <div className="column is-7-mobile is-4-tablet">
            <ProfileLink
              profileType="specialist"
              town={specialist.town}
              borough={specialist.borough}
              slug={specialist.slug}
              speciality={specialist.specialization}
              hash={specialist.practiceSlug}
            >
              <h2 className="title is-size-4 is-size-5-mobile has-text-primary has-text-weight-semibold mt-minus-5">
                {name}
              </h2>
            </ProfileLink>
            <div className={classNames(styles.specialization, 'is-size-6 has-text-dark fontweight-400')}>
              {specialist.specialization}{' '}
              {specialist.specializations ? `,${specialist.specializations.toString()}` : ''}
            </div>
            <StarRating
              size={isLessThanMobileBreakpoint ? 15 : 24}
              name="currentRating"
              value={specialist.rating}
              className={styles.rating}
            />
            <p className={`is-paddingless is-size-7 ${styles.address}`}>
              {specialist.address}, {specialist.town} {specialist.postCode}
            </p>
            {hasTimeSlots ? (
              <a
                href={`https://www.timendo.com/${specialist.clicrdvGroupName}?calendar_id=${
                  specialist.clicrdvCalendarId
                }`}
                target="__blank"
                className="is-block is-fullwidth Modal_pointer_1Rz1x button is-link is-hidden-touch is-rounded mt-60"
                onClick={handleBookingButtonClick}
              >
                <Trans>Book appointment</Trans>
              </a>
            ) : (
              <EnquireButton
                profileType="specialist"
                profileTypeId={specialist.id}
                profileTypeName={name}
                text={<Trans>Book appointment</Trans>}
                componentClassName="is-block is-fullwidth Modal_pointer_1Rz1x button is-link is-hidden-touch is-rounded mt-60"
              />
            )}
          </div>

          <div className="column is-2-mobile is-hidden-tablet is-paddingless">
            {!!specialist.distance &&
              isLessThanMobileBreakpoint && (
                <span className="tag is-size-7">
                  <Trans>{specialist.distance} mi.</Trans> <i className="fa fa-map-marker has-text-primary" />
                </span>
              )}
          </div>

          <div className="column is-12-mobile is-6-tablet pl-20 pr-20">
            <BookingCalendarMultiSpec
              // $FlowFixMe
              practice={{
                id: specialist.practiceId,
                clicrdvGroupId: specialist.clicrdvGroupId,
                clicrdvGroupName: specialist.clicrdvGroupName,
                hasOurBooking: specialist.hasOurBooking,
                apiType: specialist.apiType,
                slug: specialist.practiceSlug,
                name: `${specialist.firstName} ${specialist.lastName}`,
              }}
              specialists={[specialist]}
              desktopCount={4}
              mobileCount={3}
              firstTimeSlot={availableTimeSlots.firstSlot}
              timeslots={availableTimeSlots.availabilities.map(date => ({
                clicrdvCalendarId: specialist.clicrdvCalendarId,
                date,
                firstTimeSlot: availableTimeSlots.firstSlot,
              }))}
              spinnerId={specialist.clicrdvCalendarId}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SearchResult;
