// @flow

import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import type { ReduxStateFlowType, SearchResultSpecialist } from 'types';

import SearchResult from './SearchResult';

export type Props = {|
  id: string,
  specialist: SearchResultSpecialist,
  selectedProfileId: number,
  onMouseEnter: (specialist: SearchResultSpecialist) => void,
|};

const getCalendarAvailableTimeSlots = (state, props) =>
  state.bookingCalendar.availableTimeSlots[props.specialist.clicrdvCalendarId];

const makeGetCalendarAvailableTimeslots = () =>
  createSelector(
    [getCalendarAvailableTimeSlots],
    calendarAvailableTimeSlots => calendarAvailableTimeSlots || { firstSlot: '', availabilities: [] }
  );

export const makeMapStateToProps = () => {
  const getCalendarAvailableTimeslots = makeGetCalendarAvailableTimeslots();
  const mapStateToProps = (state: ReduxStateFlowType, props: Props) => ({
    // $FlowFixMe
    availableTimeSlots: getCalendarAvailableTimeslots(state, props),
    isLessThanMobileBreakpoint: state.browser.lessThan.mobileBreakpoint,
  });

  // $FlowFixMe
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(SearchResult);
