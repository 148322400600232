// @flow

import React from 'react';
import Helmet from 'react-helmet';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';
import queryString from 'query-string';
import type { ContextRouter } from 'react-router';

import type { SearchResultPractice, SearchResultSpecialist, SearchResultDetails } from 'types';
import { fromDashed, fromDashedLower } from 'utils/string';

import SearchBar from 'modules/searchBar';
import Map from 'modules/searchView/Map';
import SearchResults from './SearchResults';
import Filters from './Filters';
import NotFound from './NotFound';
import Pagination from './Pagination';
import ResultDetail from './ResultDetail';
import styles from './SearchView.scss';

type Query = {|
  language: string,
  location: string,
  name: string,
  specialization: string,
  town: string,
|};

type Props = {|
  fetchSearchResultsData: (query: Query) => void,
  fetchResultsAndLocation: (query: Query) => void,
  fetchLanguages: (query: Query) => void,
  fetchGenders: (query: Query) => void,
  fetchSearchResultDetails: (query: Query) => void,
  searchResultDetails: SearchResultDetails,
  searchResultsFetched: boolean,
  searchResults: { practices: Array<SearchResultPractice>, specialists: Array<SearchResultSpecialist> },
  pages: number,

  isLessThanMobileBreakpoint: boolean,
|} & ContextRouter;

type State = {|
  selectedProfileId: string,
|};

class SearchView extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const { match, location } = props;
    const { specialization, language, town } = match.params;
    const search = queryString.parse(location.search);
    const query = {
      ...search,
      specialization,
      language,
      town,
    };

    this.state = {
      selectedProfileId: '',
    };

    props.fetchSearchResultsData(query);
  }

  componentWillReceiveProps(nextProps: Props) {
    const search = queryString.parse(nextProps.location.search);
    const query = { ...search, ...nextProps.match.params };
    const oldQuery = queryString.parse(this.props.location.search);

    if (oldQuery.page !== query.page) {
      this.props.fetchResultsAndLocation(query);
    }

    if (oldQuery.name !== query.name) {
      this.props.fetchResultsAndLocation(query);
      this.props.fetchLanguages(query);
      this.props.fetchGenders(query);
    }

    if (this.props.match.params.specialization !== query.specialization) {
      this.props.fetchResultsAndLocation(query);
      this.props.fetchLanguages(query);
      this.props.fetchGenders(query);
      this.props.fetchSearchResultDetails(query);
    }

    if (oldQuery.location !== query.location) {
      this.props.fetchResultsAndLocation(query);
      this.props.fetchSearchResultDetails(query);
    }

    if (oldQuery.gender !== query.gender) {
      this.props.fetchResultsAndLocation(query);
      this.props.fetchLanguages(query);
      this.props.fetchSearchResultDetails(query);
    }

    if (this.props.match.params.language !== query.language) {
      this.props.fetchResultsAndLocation(query);
      this.props.fetchGenders(query);
      this.props.fetchSearchResultDetails(query);
    }

    if (oldQuery.sortBy !== query.sortBy) {
      this.props.fetchResultsAndLocation(query);
      this.props.fetchSearchResultDetails(query);
    }

    return true;
  }

  handleMouseEnter = (profile: SearchResultSpecialist | SearchResultPractice) => {
    this.setState({
      selectedProfileId: profile.name
        ? `practice-${profile.id}`
        : `specialist-${profile.id}-${profile.practiceId || ''}`,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      selectedProfileId: '',
    });
  };

  render() {
    const {
      searchResultsFetched,
      searchResults,
      searchResultDetails,
      location,
      isLessThanMobileBreakpoint,
    } = this.props;

    const search = queryString.parse(location.search);
    const { params } = this.props.match;

    let pathname = `/specialists/${params.town || ''}/${params.specialization || ''}`;
    pathname += params.language ? `/${params.language}` : '';

    const query = { ...search, ...params };
    const page = query.page ? parseInt(query.page, 10) : 1;

    const meta = [];
    meta.push(query.name ? { name: 'robots', content: 'noindex, nofollow' } : {});

    let keyword = query.language ? `${fromDashedLower(query.language)} ` : '';
    keyword += query.gender ? `${fromDashedLower(query.gender)} ` : '';
    keyword += `${fromDashedLower(query.specialization)} in ${fromDashed(query.town)}`;
    const description = `Read reviews, relevant information and book ${keyword} using their current availability slots within a few clicks.`;
    meta.push(
      {
        name: 'description',
        content: description,
      },
      {
        property: 'og:description',
        content: description,
      }
    );

    let title = query.language ? `${fromDashed(query.language)} ` : '';
    title += query.gender ? `${fromDashed(query.gender)} ` : '';
    title += query.name ? `${query.name}, ` : '';
    title += `${fromDashed(query.specialization)} in ${fromDashed(query.town)}`;

    const isNotFound = !searchResults.specialists.length && !searchResults.practices.length;
    const { selectedProfileId } = this.state;

    return (
      <React.Fragment>
        <Helmet title={title} meta={meta} />

        {searchResultsFetched &&
          (isNotFound ? (
            <NotFound query={query} />
          ) : (
            <div className={styles.container}>
              <div className="has-magic-gradient pt-15 pb-10 is-hidden-mobile">
                <div className="container">
                  <SearchBar
                    name={query.name}
                    specialization={query.specialization}
                    location={query.location}
                    town={query.town}
                  />
                </div>
              </div>

              <Filters {...this.props} params={this.props.match.params} pathname={pathname} />

              <div className="main-wrapper pb-10 pt-10">
                <div className="container">
                  <div className="columns">
                    <div
                      className={classNames({
                        'column is-8': true,
                        'is-paddingless': isLessThanMobileBreakpoint,
                      })}
                    >
                      <React.Fragment>
                        <SearchResults
                          searchResults={searchResults}
                          query={query}
                          selectedProfileId={selectedProfileId}
                          onMouseEnter={this.handleMouseEnter}
                          onMouseLeave={this.handleMouseLeave}
                        />
                        <Pagination page={page} pages={this.props.pages} pathname={pathname} query={search} />
                        {searchResultDetails && <ResultDetail html={searchResultDetails.text} />}
                      </React.Fragment>
                    </div>
                    <div className="column is-4 fontfamily-roboto pt-5 is-hidden-mobile">
                      <h5 className="title is-5 pt-5 is-hidden-mobile">&nbsp;</h5>
                      <Map
                        specialists={searchResults.specialists}
                        practices={searchResults.practices}
                        selectedProfileId={selectedProfileId}
                        onSelect={this.handleMouseEnter}
                      />
                      <h6 className="title is-6 has-text-grey mt-30">
                        <Trans>Find Doctors and Make Appointments Online</Trans>
                      </h6>
                      <p className="has-text-grey-light is-size-7">
                        <Trans>
                          Managing your healthcare is easier than ever before with Findoc. Just search for a doctor in
                          your insurance network, see available times, and book an appointment on the spot! You can read
                          real doctor reviews from other patients, see the doctor’s background and education, view
                          photos of the office, and more. It’s simple, secure, and free!
                        </Trans>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </React.Fragment>
    );
  }
}

export default SearchView;
