// @flow

import React from 'react';
import type { Location } from 'react-router';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Trans, t } from '@lingui/macro';
import { I18n } from '@lingui/react';

import type { LanguagesType, GendersType } from 'types';

import styles from './Filters.scss';

type Props = {
  pathname: string,
  push: ({ pathname: string, search: string }) => void,
  languages: LanguagesType,
  genders: GendersType,
  params: {
    language: string,
    specialization: string,
    town: string,
  },
  location: Location,
  isLessThanMobileBreakpoint: boolean,
};

const Filters = (props: Props) => {
  const { pathname, languages, genders, params, location, push, isLessThanMobileBreakpoint } = props;
  const search = queryString.parse(location.search);
  const query = { ...search, page: undefined };
  const hasGender = genders.length > 1;

  const handleLanguageChange = e => {
    push({
      pathname: `/specialists/${params.town}/${params.specialization}/${e.target.value}`,
      search: queryString.stringify(query),
    });
  };

  const handleGenderChange = e => {
    push({
      pathname,
      search: queryString.stringify({
        ...query,
        gender: e.target.value,
      }),
    });
  };

  const handleSortbyChange = e => {
    push({
      pathname,
      search: queryString.stringify({
        ...query,
        sortBy: e.target.value,
      }),
    });
  };

  return (
    <I18n>
      {({ i18n }) => (
        <React.Fragment>
          {/* for mobile header */}
          {isLessThanMobileBreakpoint && (
            <div className={`has-text-white ${styles.searchText}`}>
              <div>
                <b>
                  <Trans>Your search:</Trans>
                </b>
              </div>
              <div>
                <Trans>
                  {params.specialization} in {params.town}
                </Trans>
              </div>
            </div>
          )}

          <div className={classNames('container', styles.container)}>
            <div className="columns">
              <div className="column">
                <div className={styles.filterWrapper}>
                  {hasGender &&
                    (!isLessThanMobileBreakpoint ? (
                      <div className={styles.gender}>
                        <Link
                          to={`${pathname}?${queryString.stringify({ ...query, gender: undefined })}`}
                          className={classNames({
                            'has-text-primary has-text-weight-semibold is-uppercase':
                              query.gender !== 'male' && query.gender !== 'female',
                          })}
                        >
                          <Trans>Any Gender</Trans>
                        </Link>
                        <Link
                          to={`${pathname}?${queryString.stringify({ ...query, gender: 'male' })}`}
                          className={classNames({
                            'has-text-primary has-text-weight-semibold is-uppercase': query.gender === 'male',
                          })}
                        >
                          <Trans>Male</Trans>
                        </Link>
                        <Link
                          to={`${pathname}?${queryString.stringify({ ...query, gender: 'female' })}`}
                          className={classNames({
                            'has-text-primary has-text-weight-semibold is-uppercase': query.gender === 'female',
                          })}
                        >
                          <Trans>Female</Trans>
                        </Link>
                      </div>
                    ) : (
                      <div className="select">
                        <select value={query.gender || ''} onChange={handleGenderChange}>
                          <option value="">{i18n._(t`Any Gender`)}</option>
                          <option value="male">{i18n._(t`Male`)}</option>
                          <option value="female">{i18n._(t`Female`)}</option>
                        </select>
                      </div>
                    ))}

                  {languages.length > 0 && (
                    <div className="select">
                      <select
                        disabled={languages.length === 1}
                        value={params.language || ''}
                        onChange={handleLanguageChange}
                      >
                        {languages.map(({ id, name }) => (
                          <option key={id} value={name.toLowerCase() !== 'english' ? name.toLowerCase() : ''}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="select">
                    <select value={query.sortBy || ''} onChange={handleSortbyChange} defaultValue="">
                      <option value="">{i18n._(t`Default order`)}</option>
                      <option value="availability">{i18n._(t`Earliest availability`)}</option>
                      <option value="rating">{i18n._(t`Highest rating`)}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </I18n>
  );
};

export default Filters;
