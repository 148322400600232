import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import ProfileLink from 'components/link/ProfileLink';
import styles from './FreeResult.scss';

const FreeResult = props => {
  const { selectedProfileId } = props;
  const { firstName, lastName, specialization, address, town, borough, postCode, slug, profileType } = props.data;

  return (
    <div
      id={props.id}
      className={`${styles.container} ${props.id === selectedProfileId ? styles.hover : ''}`}
      onMouseEnter={() => props.onMouseEnter(props.data)}
    >
      <div className={styles.detail}>
        <ProfileLink
          town={town}
          borough={borough}
          slug={slug}
          speciality={specialization}
          profileType={profileType || 'specialist'}
        >
          <h2 className="title is-5 mb-0 pb-10 is-4 has-text-primary">{`${firstName} ${lastName}`}</h2>
        </ProfileLink>
        <h6 className="title is-7 fontfamily-roboto mb-15 has-text-dark">{specialization}</h6>
        <p>
          {address},{town} {postCode}
        </p>
      </div>
      <div>
        <ProfileLink
          className="button is-link"
          town={town}
          borough={borough}
          slug={slug}
          speciality={specialization}
          profileType={profileType || 'specialist'}
        >
          <Trans>View Profile</Trans>
        </ProfileLink>
      </div>
    </div>
  );
};

FreeResult.propTypes = {
  data: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    specialization: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    town: PropTypes.string.isRequired,
    borough: PropTypes.string,
    postCode: PropTypes.string,
    slug: PropTypes.string.isRequired,
    profileType: PropTypes.string,
  }).isRequired,
  id: PropTypes.string,
  onMouseEnter: PropTypes.func.isRequired,
  selectedProfileId: PropTypes.string,
};

export default FreeResult;
