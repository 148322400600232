// @flow

import React from 'react';
import { withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
import Scroll from 'react-scroll';
import StarRating from 'components/starRating';

import type { SearchResultSpecialist, SearchResultPractice } from 'types';

import styles from './Map.scss';

export type Props = {|
  specialists: Array<SearchResultSpecialist>,
  practices: Array<SearchResultPractice>,
  selectedProfileId: number,
  onSelect: ({}) => void,
|};

type State = {|
  isOpen: string,
|};

class SearchResultMap extends React.Component<Props, State> {
  state = {
    isOpen: '',
  };

  onToggleOpen = (id: string) => {
    this.setState({
      isOpen: id,
    });
  };

  scrollToDetail = result => {
    this.props.onSelect(result);
    Scroll.scroller.scrollTo(result.mapId, {
      duration: 500,
      delay: 30,
      smooth: 'easeInOutQuint',
    });
  };

  clearHover = () => {
    this.setState({
      isOpen: '',
    });
  };

  render() {
    const { specialists, practices } = this.props;
    const selected = this.state.isOpen || this.props.selectedProfileId;

    const results = specialists.concat(practices).map(item => ({
      ...item,
      mapId: item.name ? `practice-${item.id}` : `specialist-${item.id}-${item.practiceId || ''}`,
    }));

    const center = results.length ? { lat: results[0].latitude, lng: results[0].longitude } : {};

    return (
      <GoogleMap defaultZoom={10} defaultCenter={center} onMouseOut={this.clearHover} onBlur={() => undefined}>
        {results.map(result => (
          <Marker
            key={result.mapId}
            icon={result.mapId === selected ? '/images/new/locator_hover.png' : '/images/new/locator.png'}
            position={{ lat: result.latitude, lng: result.longitude }}
            onMouseOver={() => this.onToggleOpen(result.mapId)}
            onClick={() => this.scrollToDetail(result)}
            onFocus={() => undefined}
            onBlur={() => undefined}
          >
            {result.mapId === selected && (
              <InfoWindow onCloseClick={() => this.clearHover} defaultOptions={{ disableAutoPan: true }}>
                <div className={styles.info}>
                  {result.profilePicture && (
                    <img className={styles.avatar} src={`/images/profile/Id${result.id}-ProfilPic.jpg`} alt="avatar" />
                  )}
                  <div>
                    <span
                      role="button"
                      tabIndex={0}
                      className="mb-5 is-size-6 has-text-primary"
                      onClick={() => this.scrollToDetail(result)}
                    >
                      {result.name || `${result.firstName} ${result.lastName}`}
                    </span>
                    <div className="is-size-6">
                      {result.specialization}{' '}
                      {result.specializations ? `,${JSON.stringify(result.specializations)}` : ''}
                    </div>
                    {result.rating > 0 && <StarRating size={18} name="currentRating" value={result.rating} />}
                  </div>
                </div>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </GoogleMap>
    );
  }
}

export default withGoogleMap(SearchResultMap);
