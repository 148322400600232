import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';

import SearchView from './SearchView';
import { actions } from './actions';

const mapStateToProps = appState => ({
  ...appState.searchResults,
  isLessThanMobileBreakpoint: appState.browser.lessThan.mobileBreakpoint,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...actions,
      push,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchView);
