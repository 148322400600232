// @flow

/* eslint react/no-danger: 0 */
import React from 'react';
import { Trans } from '@lingui/macro';

import styles from './ResultDetail.scss';

type Props = {
  html: string,
};

type State = {
  isHidden: boolean,
};

class ResultDetail extends React.PureComponent<Props, State> {
  state = {
    isHidden: true,
  };

  changeIsOpen = () => {
    this.setState(prevState => ({
      isHidden: !prevState.isHidden,
    }));
  };

  render() {
    const { isHidden } = this.state;
    return (
      <React.Fragment>
        <div className={`container ${styles.container} ${isHidden ? styles.faddingOut : ''}`}>
          <div
            dangerouslySetInnerHTML={{
              __html: this.props.html,
            }}
          />
        </div>
        {isHidden && (
          <button className="button button-link has-text-primary is-paddingless" onClick={this.changeIsOpen}>
            <Trans>Show more</Trans>
          </button>
        )}
      </React.Fragment>
    );
  }
}

export default ResultDetail;
